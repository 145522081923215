<template lang="html">
  <div id="article-page">

    <section class="container main-section">
      <div class="row justify-content-center">
        <div class="col-lg-11 mb-3 col-image">
          <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
            <!-- <img src="public/images/shared/empty.png"> -->
          </div>
        </div>

        <div class="col-lg-11 col-content">
          <div class="mb-2">
            <h1 class="title-s-1">{{blog.title}}</h1>
          </div>

          <div class="box-content" v-html="blog.body"></div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      id:null,
      blog:{}
    }
  },
  methods:{
      getBlog(){
        axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
            this.blog = response.data;

          }).catch((error)=>{
              this.$parent.handleErrors(error);
          });
      },
  },
  mounted(){
    if(this.$route.params.id){
				this.id=this.$route.params.id;
        this.getBlog();
    }
  }
}
</script>
