<template lang="html">
  <div id="properties-page">

    <section class="container oversized-container results-section">
      <div class="row" v-bind:class="{ 'map-active' : displayAs == 'map' }">
        <!-- Col map -->
        <div class="col-lg-12 col-map" v-if="displayAs == 'map'">
          <div class="map-container" id="box-map-container">
            <GmapMap
              map-type-id="roadmap"
              :center="mapCenter.position"
              :zoom="mapCenter.position.zoom">
              <GmapMarker
                :key="'marker-'+mInx"
                v-for="(m, mInx) in properties"
                :position="{lat: m.lat, lng: m.lng}"
                :title="m.name"
                :clickable="true"
                :draggable="false"
                :icon="{ url: m.icon}"
                @mouseover="markAction(m)"
                @mouseout="windowOpen = false"
                @click="m.typefront == 'property' ? $router.push('/propiedades/'+m.id) : $router.push('/desarrollos/'+m.id)"
              />
              <gmap-info-window
                @closeclick="windowOpen = false"
                :opened="windowOpen"
                :position=" {lat: gmapInfo.lat, lng: gmapInfo.lng}"
                :options="{
                  pixelOffset: {
                    width: 0, height: -35
                  }
                }"
              >
              <div class="box-gmap-info-s1">
                    <div class="placed-backg b-image" v-bind:style="{ backgroundImage: 'url('+gmapInfo.imageUrl+')' }"></div>
                    <div class="b-text">
                      <h6 class="name">{{gmapInfo.name}}</h6>
                      <p v-if="gmapInfo.typefront == 'property'">
                        <span class="type">{{gmapInfo.type_operation_name}}</span>
                      </p>
                      <p v-else>
                        <span class="type">Desarrollo</span>
                      </p>

                      <h6 v-if="gmapInfo.typefront == 'property'" class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(gmapInfo.price) }} {{gmapInfo.currency}}</h6>
                      <h6 v-if="gmapInfo.typefront == 'project'" class="price">Desde $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(gmapInfo.price) }} {{gmapInfo.currency}}</h6>
                    </div>
                  </div>
              </gmap-info-window>
            </GmapMap>
          </div>
        </div>
        <!--  -->

        <!-- Col content -->
        <div class="col-lg-12 col-content">
          <div class="mb-1 row row-filters">
            <div class="col-12 col-title-t1">
              <div class="inside">
                <h2 class="title" v-if="filtertype == 0">Desarrollos disponibles</h2>
                <h2 class="title" v-else>Propiedades disponibles</h2>
              </div>
            </div>

            <div class="col-12 col-options-s2" v-if="viewportW > 992">
              <a class="btn-opt map" v-bind:class="{ active : displayAs == 'map' }" @click="displayAs = 'map'">Mapa</a>
              <a class="btn-opt list" v-bind:class="{ active : displayAs == 'list' }" @click="displayAs = 'list'">Lista</a>
            </div>

            <div class="col-lg-12 col-options-s1">
              <!-- <a class="btn btn-s-3" v-bind:class="{ active : displayAs == 'map' }" @click="displayAs = 'map', filtertype = null">MAPA <i class="map-icon"></i></a> -->
              <a class="btn btn-s-3" v-bind:class="{ active : filtertype == cInx }" @click="filtertype = cInx" v-for="(category, cInx) in categories" :key="'cInx-'+cInx">{{ category.name }} <i class="circle" v-bind:style="{ backgroundColor: category.hex }"></i></a>
            </div>
          </div>

          <div class="col-12 col-results" v-if="properties.length > 0">
            <div class="row row-properties">
              <div class="col-md-6 col-lg-4 col-xl-3 col-property-sample-2" v-for="(p, phInx) in properties" :key="'phInx-'+phInx">
                <router-link class="box-property" :to=" p.typefront == 'property' ? '/propiedades/'+p.id : '/desarrollos/'+p.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl +')' }">
                    <img src="public/images/pages/properties/property.png">
                  </div>

                  <div class="box-info">
                    <h6 class="name">{{p.name}}</h6>

                    <h5 class="price" v-if="p.typefront == 'property'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="price" v-else>Desde $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type" v-if="p.typefront == 'property'"><span>{{p.type_operation_name}}</span></h5>

                    <div class="box-extra-info" v-if="p.typefront == 'property'">
                      <div class="col col-info">
                              <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                          </div>

                          <div class="col col-info">
                            <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                          </div>

                          <div class="col col-info">
                            <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                          </div>

                        <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                        <!-- <div class="col col-info" v-if="p.meters_ground_sqft">
                              <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_building_sqft">
                            <i class="icon built"></i> <span class="txt">{{p.meters_building_sqft}} SQFT</span>
                        </div>

                        <div class="col col-info" v-if="p.meters_garden_sqft">
                            <i class="icon garden"></i> <span class="txt">{{p.meters_garden_sqft}} SQFT</span>
                        </div> -->
                    </div>
                  </div>

                  <div class="btn-more">EXPLORAR</div>
                </router-link>
              </div>

            </div>

            <div class="row">
              <div class="col-12 mt-3 col-pagination">
                <b-pagination-nav class="pagination-s1" :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
              </div>
            </div>
          </div>
          <div class="col-12 col-results" v-else>
            <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 0">Por ahora no existen desarrollos</p>
            <p style="text-align: center;font-size: 20px;width: 100%;" v-else>Por ahora no existen propiedades disponibles bajo esta clasificacion</p>
          </div>

        </div>
        <!--  -->
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      filtertype: 0,

      categories: [
        { name: 'Desarrollos', hex: '#0bc0df' },
        { name: 'Propiedad Vacacional', hex: '#06bf64' },
        { name: 'Propiedad', hex: '#ff3232' },
        { name: 'Terreno', hex: '#ffde59' },
        { name: 'Comercial', hex: '#b34eff' },
        { name: 'Oficina', hex: '#a3a3a3' },

      ],

      properties: [],
      projects:[],
      propertiesAll:[],

      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_properties:1,
      },

      // == Map scroll values ==
      displayAs: 'map',
      scrollY: 0,
      viewportW: 0,
      viewportH: 0,
      headerH: 0,
      mapPositionY: 0,
      mapH: 0,
      // == ==

      // == Markers values ==
      mapCenter: { id: null, position: { lat: 20.6906326, lng: -105.3213846, zoom: 12.7 } }, // Centro del mapa
      markers: [ ],
      windowOpen: false, // true, false para mostrar la ventana de los markers
      gmapInfo: { // Obj para la ventana de los markers
        position: {
          lat: 0, 
          lng: 0,
          zoom: 0,
        },
        content: ``
      },
      // == ==
    }
  },

  methods:{
    handleScroll(event){
      this.scrollY = window.scrollY;
      // console.log(this.scrollY);
      this.viewportW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.viewportH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      this.headerH = document.getElementById( 'header' ).offsetHeight;
      this.mapH = this.viewportH - this.headerH;
      this.mapPositionY = this.headerH;

      if(this.viewportW >= 992 && this.displayAs == 'map') { // El mapa solo se mostrará en escritorio y si está seleccionado "map"
        document.getElementById('box-map-container').setAttribute('style','top:'+this.mapPositionY+'px;height:'+this.mapH+'px;');
      } else {
        this.displayAs = 'list';
      }
    },

    markAction(val) {
      this.gmapInfo = val;
      this.windowOpen = true;
    },

    getData(){
      axios.get(tools.url("/api/properties")).then((response)=>{
          this.properties = response.data.projects;
          this.projects = response.data.projects;
          this.propertiesAll = response.data.properties;
        }).catch((error)=>{
      });
    },

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },

  mounted(){
    this.getData();

    setTimeout(()=>{
      this.handleScroll();
    }, 500);
  },

  watch: {
    displayAs(newVal, oldVal) {
      if(newVal == 'map') {
        setTimeout(()=>{
          this.handleScroll();
        }, 300);
      }
    },
    'filtertype':function(){
          this.properties = [];
          if(this.filtertype == 0 ){
              this.projects.forEach(element => {

                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-1.svg';
                    this.properties.push(element);

              });
          }
          else if(this.filtertype == 1){
            this.propertiesAll.forEach(element => {
                  if(element.type == 'vacation_property'){
                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-4.svg';
                    this.properties.push(element);
                  }
              });
          }
          else if(this.filtertype == 2){
            this.propertiesAll.forEach(element => {
                  if(element.type == 'property'){
                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-2.svg';
                    this.properties.push(element);
                  }
              });
          }
          else if(this.filtertype == 3){
            this.propertiesAll.forEach(element => {
                  if(element.type == 'ground'){
                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-3.svg';
                    this.properties.push(element);
                  }
              });
          }
          else if(this.filtertype == 4){
            this.propertiesAll.forEach(element => {
                  if(element.type == 'commercial'){
                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-5.svg';
                    this.properties.push(element);
                  }
              });
          }
          else if(this.filtertype == 5){
            this.propertiesAll.forEach(element => {
                  if(element.type == 'office'){
                    element.icon = 'https://vrnrealty.com.mx/public/images/shared/map-circle-6.svg';
                    this.properties.push(element);
                  }
              });
          }
      }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  },
}
</script>
