import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      { path: '/empresa', component: require(page+'about-us/index.vue').default, meta:{title:"Empresa"}},
      { path: '/alianzas', component: require(page+'alliance/index.vue').default, meta:{title:"Alianzas"}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},
      { path: '/preguntas-frecuentes', component: require(page+'faqs/index.vue').default, meta:{title:"Preguntas frecuentes"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},

      { path: '/trabaja-con-nosotros', component: require(page+'jobs/index.vue').default, meta:{title:"Trabaja con nosotros"}},
      { path: '/trabaja-con-nosotros/formulario', component: require(page+'jobs/form.vue').default, meta:{title:"Trabaja con nosotros"}},

      { path: '/vender', component: require(page+'sales/index.vue').default, meta:{title:"Vende tu desarrollo con nosotros"}},
      { path: '/vender/formulario', component: require(page+'sales/form.vue').default, meta:{title:"Vende tu desarrollo con nosotros"}},

      { path: '/desarrollos', component: require(page+'development/index-zantamar.vue').default, meta:{title:"Proyectos"}},
      { path: '/desarrollos/:id', component: require(page+'development/detail.vue').default, meta:{title:"Proyecto"}},
      // { path: '/desarrollos/:id', component: require(page+'development/detail.vue').default, meta:{title:"Proyecto"}},

      { path: '/destinos', component: require(page+'destinations/index.vue').default, meta:{title:"Destinos"}},
      { path: '/destinos/:id', component: require(page+'destinations/results.vue').default, meta:{title:"Destinos"}},

      { path: '/propiedades', component: require(page+'properties/index.vue').default, meta:{title:"Propiedades"}},
      { path: '/propiedades/:id', component: require(page+'properties/detail.vue').default, meta:{title:"Propiedad"}},

      { path: '/blogs', component: require(page+'blog/index.vue').default, meta:{title:"Isla de Cozumel"}},
      { path: '/blogs/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Comunicado"}},

      { path: '/lotes', component: require(page+'lotes/index.vue').default, meta:{title:"Lotes"}},
      { path: '/lotes/:id', component: require(page+'lotes/detail.vue').default, meta:{title:"Lote"}},

      { path: '/busqueda', component: require(page+'search/index.vue').default, meta:{title:"Búsqueda"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "VRN REALTY", filter: (title)=>{ return title+" - VRN REALTY"; } }
);

// export {routes};
export default MyRouter;
