<template lang="html">
  <footer id="footer">

		<section class="newsletter-section">
			<h5>Se el primero en saber</h5>
      <h2>Sucríbete a nuestro Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Escribe tu correo"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Subscribe</b-button>
      </b-form>
    </section>

		<section class="container oversized-container footer-section">
			<div class="row align-items-center">
				<div class="col-lg-2 col-footer col-logo">
					<router-link to="/">
						<img src="public/images/logo.png">
					</router-link>
				</div>

				<div class="col-lg-3 col-footer">
					<h5 class="title">Información</h5>

          <p>
						<router-link to="/alianzas">Alianzas</router-link>
					</p>
          <p>
						<router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link>
					</p>
					<p>
						<router-link to="/trabaja-con-nosotros">Trabaja con nosotros</router-link>
					</p>
          <p>
						<router-link to="/lifestyle">Lifestyle</router-link>
					</p>
					<p>
						<router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
					</p>
				</div>

        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-6 col-footer">
    					<h5 class="title">Oficinas Guadalajara</h5>

              <p>
    						Cuauhtémoc #70 - 7<br />
                Bucerias, Nayarit,<br />
                Jalisco.
    					</p>
    				</div>

            <div class="col-sm-6 col-footer">
              <h5 class="title">Oficinas R. Nayarit</h5>

              <p>
                Av. Universidad 1011. Piso 3<br />
                45136 Zapopan,<br />
                Jalisco.
    					</p>
    				</div>
          </div>
        </div>

				<div class="col-lg-3 col-footer col-info">
					<div class="row mx-0 mb-2 mb-lg-1 justify-content-center justify-content-lg-end">
						<div class="my-1 box-networks">
							<a class="btn-network" href="https://www.facebook.com/PropiedadesRivieraNayarit">
								<i class="fab fa-facebook-f"></i>
							</a>
							<a class="btn-network" href="https://www.instagram.com/vrnrealty/">
								<i class="fab fa-instagram"></i>
							</a>
						</div>

						<!-- <b-form-select size="sm" class="my-1 select-lang" v-model="langSelected">
							<b-form-select-option value="Esp">ESPAÑOL (MX)</b-form-select-option>
							<b-form-select-option value="Eng">INGLES</b-form-select-option>
						</b-form-select> -->
					</div>

					<p class="mt-1 mt-lg-2">
						Email. <a style="color: ffffff;" href="mailto:info@vrnrealty.com.mx">info@vrnrealty.com.mx</a>
					</p>
					<p class="mt-1 mt-lg-2">
						Derechos Reservados  © VRN Realty
					</p>
				</div>
			</div>
		</section>
  </footer>
</template>

<script>
export default {
	data(){
		return{
			langSelected: 'Esp',

			form: {
        email: null
      },
		}
	},

	methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  }
}
</script>
