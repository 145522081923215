<template lang="html">
  <div id="jobs-page">

    <section class="main-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-info">
            <h1 class="title">¡Trabaja con nosotros!</h1>

            <h4 class="subtitle">Siempre tenemos<br />Espacio para el Talento</h4>

            <p>
              <img class="icon" src="public/images/pages/jobs/job-icon.svg">
            </p>
            <p class="mt-4">
              <router-link class="btn btn-s-4 gray-light" to="/trabaja-con-nosotros/formulario">ÚNETE A NUESTRO EQUIPO</router-link>
            </p>

            <h6 class="mt-4 title-2">¿Estás preparado para demostrar tu talento?</h6>
            <p>
              Buscamos asesores, gerentes, editores de video, con ganas de cumplir sus objetivos.
            </p>

            <h6 class="mt-5 title-3 f-w-700">¡UNETE A LA FAMILIA VRN REALTY!</h6>

            <div class="mt-4 box-benefits">
              <div class="col-lg-4 col-benefit">
                <p>
                  <img class="icon" src="public/images/pages/jobs/benefit-1.svg">
                </p>
                <h6 class="mt-3">Crecimiento exponencial</h6>
              </div>

              <div class="col-lg-4 col-benefit">
                <p>
                  <img class="icon" src="public/images/pages/jobs/benefit-2.svg">
                </p>
                <h6 class="mt-3">Herramientas financieras</h6>
              </div>

              <div class="col-lg-4 col-benefit">
                <p>
                  <img class="icon" src="public/images/pages/jobs/benefit-3.svg">
                </p>
                <h6 class="mt-3">Capacitación constante</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
