<template lang="html">
  <div id="project-page-v2">

    <section class="banner-section">
      <swiper class="swiper" :options="bannersOptions">
        <swiper-slide>
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+project.imageUrl+')' }">
            <img src="public/images/pages/projects/banner.png">
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </section>

    <section class="gallery-section">
      <div class="container-fluid">
        <div class="box-gallery">
          <swiper class="swiper swiper-s1" :options="galleryOptions">
            <swiper-slide v-for="(image, imageIndex) in project.images" :key="'imageIndex-'+imageIndex">
              <div class="placed-backg box-photo" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="setIndex(imageIndex)">
                <img src="public/images/shared/empty.png">
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container about-us-section">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-text">
          <h1 class="mb-2 text-center project-name">{{project.name}}</h1>
          <div class="descr" v-html="project.description"></div>

          <h4 class="text-center price">Desde $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(project.price) }} {{project.currency}}</h4>
        </div>

        <div class="col-lg-10 mt-3 col-video">
          <iframe :src="project.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </section>

    <section class="container amenities-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2 class="title-s-1">Amenidades</h2>
        </div>

        <div class="col-12 box-amenities-container-s1">
          <!-- <div class="box total"><span><small class="w-100">Terreno</small><br />270 MTS²</span></div>
          <div class="box built"><span><small class="w-100">Construido</small><br />230 MTS²</span></div>
          <div class="box rooms"><span><small class="w-100">Cuartos</small><br />4</span></div>
          <div class="box b-rooms"><span><small class="w-100">Baños</small><br />3</span></div>
          <div class="box cars"><span><small class="w-100">Estac.</small><br />1</span></div>
          <div class="box garden"><span><small class="w-100">Jardín</small><br />No</span></div>
          <div class="box security"><span><small class="w-100">Seguridad 24/7</small></span></div>
          <div class="box field"><span><small class="w-100">Canchas</small></span></div> -->

          <div class="box beach-pool" v-if="project.beachclubpool == 1"><span><small class="w-100">Beach Club Pool</small></span></div>
          <div class="box gym" v-if="project.gym == 1"><span><small class="w-100">Gimnasio</small></span></div>
          <div class="box playground" v-if="project.gamingyard == 1"><span><small class="w-100">Patio de juegos</small></span></div>
          <div class="box pool" v-if="project.pool == 1"><span><small class="w-100">Alberca</small></span></div>
          <div class="box spa" v-if="project.spa == 1"><span><small class="w-100">Spa</small></span></div>
          <div class="box elevator" v-if="project.elevator == 1"><span><small class="w-100">Elevadores</small></span></div>
          <div class="box business-center" v-if="project.bussinesscenter == 1"><span><small class="w-100">Business Center</small></span></div>
          <div class="box yoga-room" v-if="project.yogaroom == 1"><span><small class="w-100">Yoga room</small></span></div>
          <div class="box pet-friendly" v-if="project.pertfriendly == 1"><span><small class="w-100">Pet Friendly</small></span></div>
          <div class="box coworking" v-if="project.coworking == 1"><span><small class="w-100">Coworking</small></span></div>
          <div class="box access" v-if="project.controlledaccess == 1"><span><small class="w-100">Acceso controlado</small></span></div>
          <div class="box sea" v-if="project.accesssea == 1"><span><small class="w-100">Acceso al mar</small></span></div>
          <div class="box beach-loungers" v-if="project.beachloungers == 1"><span><small class="w-100">Beach loungers</small></span></div>
          <div class="box store" v-if="project.stores == 1"><span><small class="w-100">Tiendas</small></span></div>
          <div class="box motor-lobby" v-if="project.motorlobby == 1"><span><small class="w-100">Motor Lobby</small></span></div>
        </div>
      </div>
    </section>

    <section class="maps-section">
      <GmapMap
        map-type-id="roadmap"
        :center="{lat:project.lat, lng:project.lng}"
        :zoom="14.5"
        map-type-id="terrain">
        <GmapMarker
          :position="{lat:project.lat, lng:project.lng}"
          :clickable="false"
          :draggable="false"
          v-if="project.lat != null && project.lng != null"/>
       </GmapMap>
    </section>

    <section class="extra-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-extra">
            <a type="button" class="btn btn-s-4 gray-light" @click="downloadFile(1)">Floor plan</a>
          </div>
          <div class="col-lg-4 col-extra">
            <a type="button" class="btn btn-s-4 gray-light" @click="downloadFile(2)">Inventario</a>
          </div>
          <div class="col-lg-4 col-extra">
            <a type="button" class="btn btn-s-4 gray-light" @click="downloadFile(3)">Progreso de obra</a>
          </div>
        </div>
      </div>

      <sweet-modal ref="modalInfo">
        <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-s1">
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder="NOMBRE COMPLETO"
                  name="Nombre"
                  required
                ></b-form-input>
            </b-form-group>
            <b-form-group class="custom-f-group-s1">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder="CORREO ELECTRONICO"
                  name="Correo"
                  required

                ></b-form-input>
            </b-form-group>
            <b-form-group class="custom-f-group-s1">
                <b-form-input
                  v-model="form.phone"
                  type="text"
                  placeholder="TELEFONO"
                  name="Telefono"
                  required
                ></b-form-input>
            </b-form-group>

            <div class="col-12 text-center">
                  <b-button type="submit" class="t-400 btn-s-4 blue">Enviar</b-button>
                </div>
        </b-form>
      </sweet-modal>

    </section>

    <CoolLightBox
      :items="project.images"
      :index="index"
      :effect="'fade'"
      loop
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data(){
    return{
      id:null,

      project:{
        lat:20.66936598231273,
					lng:-103.34820660887827,
          images:[],
      },

      index: null,

      map: {
        lat:	20.7476158,
        lng:	-105.3820489,
      },

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 750,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      galleryOptions: {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: false,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1500: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1: {
            slidesPerView: 2,
            spaceBetween: 15
          },
        }
      },
      // == ==
      form:{
        name:null,
        email:null,
        phone:null,
        projects_id:null
      },
      candownload:false,
      currenttype:null,
    }
  },

  methods:{
    setIndex(index) {
      this.index = index
    },

    getProject(){
      axios.get(tools.url("/api/projects/"+this.id)).then((response)=>{
        this.project = response.data;

      }).catch((error)=>{
        this.$parent.handleErrors(error);
      });
    },
    downloadFile(type){
        this.currenttype = type;
        if(this.candownload == true){
          if(type == 1){
              window.open(this.project.documentUrlfloorplan,'_blank');
          }
          else if(type == 2){
              window.open(this.project.documentUrlinventory,'_blank');
          }
          else if(type == 3){
              window.open(this.project.documentUrlbuildprogress,'_blank');
          }
        }
        else{
            this.$refs.modalInfo.open();
        }



    },
    onSubmit(evt) {
      evt.preventDefault();
      this.form.projects_id = this.id;
      var params = this.form;
      var apiURL = tools.url("/api/projectRequest");

      axios.post( apiURL,params ).then( (response) => {
          this.$refs.modalInfo.close();
          this.candownload = true;
          this.downloadFile(this.currenttype);
      })
      .catch( (error) => {
        alert('error');
      });
    }
  },

  mounted(){
    if(this.$route.params.id){
		 		this.id=this.$route.params.id;
        this.getProject();
    }
  }
}
</script>
