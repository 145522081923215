<template>
    <div>
      <ol class="breadcrumb 2">
              <li><router-link to="/home"><i class="entypo-home"></i>Home</router-link></li>
              <li class="active"><strong>Propiedades</strong></li>
          </ol>
          <h2 class="margin-bottom">Propiedades</h2>
  
          <div class="row">
              <div class="col-md-12">
                  <div id="toolbar">
                      <router-link to="/properties/edit">
                          <button class="btn btn-success btn-sm">
                              <i class="fa fa-plus"></i> Nuevo
                          </button>
                      </router-link>
                      <button class="btn btn-danger btn-sm" @click="deleteRows()">
                          <i class="fa fa-trash"></i> Borrar
                      </button>
                      <button class="btn btn-info btn-sm" @click="outstandingRows()">
                          <i class="fa fa-star"></i> Destacar / No destacar
                      </button>
                  </div>
                  <table id="table"></table>
              </div>
          </div>
      </div>
  </template>
  <script type="text/javascript">
    export default {
    data(){
      return {
        rows:[],
      }
    },
    methods:{
      mounthTable(){
        jQuery('#table').bootstrapTable({
          columns: [
            {
              field:"check",
              checkbox:true,
              align: 'center',
            },          
            {
              field: 'id',
              title: 'ID',
              sortable:true,
              switchable:true,
            },
            {
              field: 'imagen',
              title: ' ',
              sortable:false,
              width:"52px",
            },
            {
              field: 'position',
              title: 'Posicion<br>de aparicion',
              sortable:true,
              switchable:true,
            },
            {
              field: 'name',
              title: 'Nombre',
              sortable:true,
              switchable:true,
            },
            {
              field: 'type_name',
              title: 'Tipo',
              sortable:true,
              switchable:true,
            },
            {
              field: 'type_operation_name',
              title: 'Tipo de operacion',
              sortable:true,
              switchable:true,
            },
            {
              field: 'price',
              title: 'Precio',
              sortable:true,
              switchable:true,
            },
            {
              field: 'outstandingBtn',
              title: 'Destacado',
              sortable:true,
              switchable:true,
            }
          ],
          showRefresh:true,
        });
  
        jQuery('#table').on('refresh.bs.table',()=>{
          this.getContent();
        });
  
        jQuery('#table').on('click-row.bs.table',(row,data)=>{
          this.$router.push('/properties/edit/'+data.id);
        });
  
        this.getContent();
  
      },
  
      getContent(){
        this.$parent.inPetition=true;
        axios.get(tools.url("/api/admin/properties")).then((response)=>{
            this.rows = response.data;
            jQuery('#table').bootstrapTable('removeAll');
            jQuery('#table').bootstrapTable('append',this.rows);
            this.$parent.inPetition=false;
          }).catch((error)=>{
              this.$parent.handleErrors(error);
              this.$parent.inPetition=false;
          });
      },
  
      deleteRows:function(){
        var rows=jQuery('#table').bootstrapTable('getSelections');
        if(rows.length==0){
          return false;
        }
        alertify.confirm("Alerta!","¿Seguro que deseas borrar "+rows.length+" registros?",()=>{
          this.$parent.inPetition=true;
          var params={};
          params.ids=jQuery.map(rows,(row)=>{
            return row.id;
          });
  
          axios.delete(tools.url('/api/admin/properties'),{data:params})
          .then((response)=>{
            this.$parent.showMessage(response.data.msg,"success");
            this.getContent();
            this.$parent.inPetition=false;
          })
          .catch((error)=>{
            this.$parent.handleErrors(error);
                this.$parent.inPetition=false;
          });
        },
        ()=>{
        });
      },
      outstandingRows:function(){
        var rows=jQuery('#table').bootstrapTable('getSelections');
        if(rows.length==0){
          return false;
        }
        alertify.confirm("Alerta!","¿Seguro que deseas Destacar / No destacar "+rows.length+" registros?",()=>{
          this.$parent.inPetition=true;
          var params={};
          params.ids=jQuery.map(rows,(row)=>{
            return row.id;
          });
  
          axios.post(tools.url('/api/admin/propertiesoutstanding'),{ids:params.ids})
          .then((response)=>{
            this.$parent.showMessage(response.data.msg,"success");
            this.getContent();
            this.$parent.inPetition=false;
          })
          .catch((error)=>{
            this.$parent.handleErrors(error);
                this.$parent.inPetition=false;
          });
        },
        ()=>{
        });
      },

      
    },
        mounted() {
            this.mounthTable();
        }
    }
  </script>
  