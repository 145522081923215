<template lang="html">
  <div id="properties-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row row-filters">
            <div class="col-lg-4 col-title-t1">
              <div v-if="showMap">
                <h2 class="title">Mapa de disponibles</h2>
              </div>

              <div v-else>
                <h2 class="title">{{ categories[filtertype].name }} disponibles</h2>
              </div>
            </div>

            <div class="col-lg-8 col-options-s1">
              <a class="btn btn-s-3 map" v-bind:class="{ active : showMap }" @click="showMap = true, filtertype = null">MAPA <i class="map-icon"></i></a>
              <a class="btn btn-s-3" v-bind:class="{ active : filtertype == cInx }" @click="filtertype = cInx, showMap = false" v-for="(category, cInx) in categories" :key="'cInx-'+cInx">{{ category.name }} <i class="circle" v-bind:style="{ backgroundColor: category.hex }"></i></a>
            </div>
          </div>
        </div>

        <div class="col-12 col-map-gr" v-if="showMap">
          <div class="map">
            <GmapMap
              map-type-id="roadmap"
              :center="mapCenter.position"
              :zoom="mapCenter.position.zoom">
              <GmapMarker
                :key="'marker-'+mInx"
                v-for="(m, mInx) in markers"
                :position="m.position"
                :title="m.name"
                :clickable="true"
                :draggable="false"
                :icon="{ url: m.icon}"
                @click="markAction(m)"
              />
            </GmapMap>
          </div>
        </div>

        <div class="col-12 col-results" v-else>
          <div class="row row-properties">
            <div class="col-md-6 col-lg-4 col-property-sample-1" v-for="(p, phInx) in properties" :key="'phInx-'+phInx">
              <router-link class="box-property" :to="'/propiedades/'+p.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl +')' }">
                  <img src="public/images/pages/properties/property.png">
                </div>

                <div class="box-info">
                  <h6 class="name">{{p.name}}</h6>

                  <h5 class="price">{{p.price}} {{p.currency}}</h5>
                  <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                  <div class="box-extra-info">
                      <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground}} MTS²</span>
                      </div>

                      <div class="col col-info">
                          <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building}} MTS²</span>
                      </div>

                      <div class="col col-info">
                          <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden}} MTS²</span>
                      </div>

                      <!-- <div class="col-lg-4 d-none d-xl-inline-flex"></div> -->

                      <div class="col col-info" v-if="p.meters_ground_sqft">
                            <i class="icon total"></i> <span class="txt">{{p.meters_ground_sqft}} SQFT</span>
                      </div>

                      <div class="col col-info" v-if="p.meters_building_sqft">
                          <i class="icon built"></i> <span class="txt">{{p.meters_building_sqft}} SQFT</span>
                      </div>

                      <div class="col col-info" v-if="p.meters_garden_sqft">
                          <i class="icon garden"></i> <span class="txt">{{p.meters_garden_sqft}} SQFT</span>
                      </div>
                  </div>
                </div>

                <div class="btn-more">EXPLORAR</div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      filtertype: 0,
      showMap: false,

      categories: [
        { name: 'Desarrollos', hex: '#0bc0df' },
        { name: 'Ventas', hex: '#ff3232' },
        { name: 'Rentas', hex: '#ffde59' },
        { name: 'Renta vacacional', hex: '#06bf64' },
      ],

      properties: [
        { id: 1, imageUrl: 'public/images/pages/properties/lote-2.jpg', name: 'LOTE CON EXCELENTE VISTA', price: '$ 400,000.00', currency: 'MXN', type_operation_name: 'VENTA', meters_ground: 400, meters_building: 100, meters_garden: 300, meters_ground_sqft: 40, meters_building_sqft: 10, meters_garden_sqft: 30 },
        { id: 1, imageUrl: 'public/images/pages/properties/lote-2.jpg', name: 'LOTE CON EXCELENTE VISTA', price: '$ 400,000.00', currency: 'MXN', type_operation_name: 'VENTA', meters_ground: 400, meters_building: 100, meters_garden: 300, meters_ground_sqft: 40, meters_building_sqft: 10, meters_garden_sqft: 30 },
        { id: 1, imageUrl: 'public/images/pages/properties/lote-2.jpg', name: 'LOTE CON EXCELENTE VISTA', price: '$ 400,000.00', currency: 'MXN', type_operation_name: 'VENTA', meters_ground: 400, meters_building: 100, meters_garden: 300, meters_ground_sqft: 40, meters_building_sqft: 10, meters_garden_sqft: 30 },
      ],

      // == Markers values ==
      mapCenter: { id: null, position: { lat: 20.6712689, lng: -103.3923762, zoom: 12.7 } }, // Centro del mapa
      markers: [
        { id: 1, icon: 'public/images/shared/map-circle-1.svg', name: 'Propiedad numero 1', position: { lat: 20.672891, lng: -103.346477, zoom: 17.4 }, },
        { id: 2, icon: 'public/images/shared/map-circle-1.svg', name: 'Propiedad numero 2', position: { lat: 20.690156, lng: -103.3626689, zoom: 17.4 } },
        { id: 3, icon: 'public/images/shared/map-circle-2.svg', name: 'Propiedad numero 3', position: { lat: 20.6730545, lng: -103.4047245, zoom: 17.4 } },
        { id: 4, icon: 'public/images/shared/map-circle-3.svg', name: 'Propiedad numero 4', position: { lat: 20.6653555, lng: -103.4064315, zoom: 17.4 } },
        { id: 5, icon: 'public/images/shared/map-circle-3.svg', name: 'Propiedad numero 5', position: { lat: 20.67022, lng: -103.3120299, zoom: 17.4 } } ,
        { id: 6, icon: 'public/images/shared/map-circle-4.svg', name: 'Propiedad numero 6', position: { lat: 20.634309, lng: -103.3834509, zoom: 17.4 } },
      ],
      // == ==
    }
  },
  methods:{
    markAction() {
      this.$router.push({ path: '/propiedades/1' });
    },

      getData(){
        axios.get(tools.url("/api/ground")).then((response)=>{
            this.properties = response.data;
          }).catch((error)=>{
          });
      },

  },
  mounted(){
      this.getData();

  }
}
</script>
