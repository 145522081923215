<template lang="html">
  <div id="properties-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12">
          <h1 class="title-s-1 mb-2">Resultados de la búsqueda</h1>
        </div>

        <div class="col-12 col-results">
          <div class="row row-properties">
            <div class="col-md-6 col-lg-4 col-property-sample-1" v-for="(p, phInx) in 3" :key="'phInx-'+phInx">
              <router-link class="box-property" to="/propiedades/1">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/properties/property-1.jpg)' }">
                  <img src="public/images/pages/properties/property.png">
                </div>

                <div class="box-info">
                  <h6 class="name">Nombre de unidad habitacional</h6>

                  <h5 class="price">$5,000.000 MXN</h5>

                  <div class="box-extra-info">
                    <div class="col col-info">
                      <i class="icon width"></i> <span class="txt">6 MTS²</span>
                    </div>

                    <div class="col col-info">
                      <i class="icon height"></i> <span class="txt">10 MTS²</span>
                    </div>

                    <div class="col-lg-4 d-none d-xl-inline-flex"></div>

                    <div class="col col-info">
                      <i class="icon rooms"></i> <span class="txt sm">2 CUARTOS</span>
                    </div>

                    <div class="col col-info">
                      <i class="icon b-rooms"></i> <span class="txt sm">2 1/2 BAÑOS</span>
                    </div>

                    <div class="col col-info">
                      <i class="icon cars"></i> <span class="txt sm">2 ESPACIOS</span>
                    </div>
                  </div>
                </div>

                <div class="btn-more">EXPLORAR</div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
