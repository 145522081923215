<template lang="html">
  <div id="about-us-page-v2">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-info">
            <h1 class="title">Empresa</h1>

            <p>
              Somos una empresa especializada en propiedades de playa, con más de 5 años de experiencia en el mundo inmobiliario.
            </p>

            <br />
            <p>
              Ofrecemos un servicio personalizado y de calidad para atenderte en todas tus necesidades.
            </p>

            <br />
            <p>
              En nuestra agencia inmobiliaria, nos enorgullece ofrecer un enfoque personalizado para cada cliente.
            </p>
          </div>

          <div class="col-lg-6 offset-lg-1 col-image">
            <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image.jpg)' }">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extra-info-section">
      <div class="content">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h5 class="subtitle">En nuestra agencia inmobiliaria, nos enorgullece ofrecer un enfoque personalizado para cada cliente.</h5>
              <p class="mt-1">
                Trabajamos con propietarios, compradores e inversores para crear conexiones exitosas y lograr transacciones inmobiliarias exitosas.
              </p>

              <br />
              <p>
                Nuestra reputación se basa en nuestra integridad, conocimiento del mercado y atención meticulosa a los detalles.
              </p>

              <br />
              <p>
                Aprovechamos el poder del marketing digital para llegar a un público más amplio.
              </p>

              <!-- <div class="box-circles">
                <div class="col-12 col-title">
                  <h4 class="title">¿Porque vender con nosotros?</h4>
                </div>

                <div class="col-sm-6 col-md-3 col-reason">
                  <div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/img-1.jpg)' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="b-text">
                    <h6>Redes sociales</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3 col-reason">
                  <div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/img-2.jpg)' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="b-text">
                    <h6>+20 Plataformas</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3 col-reason">
                  <div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/img-3.jpg)' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="b-text">
                    <h6>Branding</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3 col-reason">
                  <div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/img-4.jpg)' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="b-text">
                    <h6>Fotografía profesional</h6>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="content bg-2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="box-extra-numbers-s1">
                <div class="col-lg-4 col-numbers">
                  <h3 class="number">+50</h3>
                  <h5 class="text">Desarrollos</h5>
                </div>

                <div class="col-lg-4 col-numbers">
                  <h3 class="number">+700</h3>
                  <h5 class="text">Clientes felices</h5>
                </div>

                <div class="col-lg-4 col-numbers">
                  <h3 class="number">+1,000</h3>
                  <h5 class="text">Propiedades en la playa</h5>
                </div>

                <div class="col-12 col-buttons">
                  <router-link class="btn btn-prop" to="/propiedades">VER PROPIEDADES</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
}
</script>
