<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.movil" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="properties-section">
      <div class="container oversized-container">
        <div class="mb-3 row align-items-center">
          <div class="col-lg-4 col-title-t1">
            <div v-if="showMap">
              <h2 class="title">Mapa de disponibles</h2>
            </div>

            <div v-else>
              <h2 class="title" v-if="filtertype == 0">Desarrollos disponibles</h2>
              <h2 class="title" v-else>Propiedades disponibles</h2>
              <router-link class="btn-all" v-if="filtertype == 0" to="/desarrollos">VER TODOS</router-link>
              <router-link class="btn-all" v-if="filtertype == 1" to="/propiedades">VER TODAS</router-link>
              <router-link class="btn-all" v-if="filtertype == 2" to="/propiedades">VER TODAS</router-link>
              <router-link class="btn-all" v-if="filtertype == 3" to="/propiedades">VER TODAS</router-link>
              <router-link class="btn-all" v-if="filtertype == 4" to="/propiedades">VER TODAS</router-link>
              <router-link class="btn-all" v-if="filtertype == 5" to="/propiedades">VER TODAS</router-link>
            </div>
          </div>

          <div class="col-lg-8 col-options-s1">
            <a class="btn btn-s-3 map" v-bind:class="{ active : showMap }" @click="showMapFun()">
              <span v-if="showMap == true">Listado <i class="map-list"></i></span>
              <span v-else>Mapa <i class="map-icon"></i></span>
          </a>
            <a class="btn btn-s-3" style="margin: 0px !important;" v-bind:class="{ active : filtertype == cInx }" @click="filtertype = cInx, showMap = false" v-for="(category, cInx) in categories" :key="'cInx-'+cInx">{{ category.name }} <i class="circle" v-bind:style="{ backgroundColor: category.hex }"></i></a>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-map-gr" v-if="showMap">
            <div class="map">
              <GmapMap
                map-type-id="roadmap"
                :center="mapCenter.position"
                :zoom="mapCenter.position.zoom">
                <GmapMarker
                  :key="'marker-'+mInx"
                  v-for="(m, mInx) in properties.maps"
                  :position="{lat: m.lat, lng: m.lng}"
                  :title="m.name"
                  :clickable="true"
                  :draggable="false"
                  :icon="{ url: m.icon}"
                  @mouseover="markAction(m)"
                  @mouseout="windowOpen = false"
                  @click="m.typefront == 'property' ? $router.push('/propiedades/'+m.id) : $router.push('/desarrollos/'+m.id)"
                />

                <gmap-info-window
                  @closeclick="windowOpen = false"
                  :opened="windowOpen"
                  :position=" {lat: gmapInfo.lat, lng: gmapInfo.lng}"
                  :options="{
                    pixelOffset: {
                      width: 0, height: -35
                    }
                  }"
                >
                  <div class="box-gmap-info-s1">
                    <div class="placed-backg b-image" v-bind:style="{ backgroundImage: 'url('+gmapInfo.imageUrl+')' }"></div>
                    <div class="b-text">
                      <h6 class="name">{{gmapInfo.name}}</h6>
                      <p v-if="gmapInfo.typefront == 'property'">
                        <span class="type">{{gmapInfo.type_operation_name}}</span>
                      </p>
                      <p v-else>
                        <span class="type">Desarrollo</span>
                      </p>

                      <h6 v-if="gmapInfo.typefront == 'property'" class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(gmapInfo.price) }} {{gmapInfo.currency}}</h6>
                      <h6 v-if="gmapInfo.typefront == 'project'" class="price">Desde $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(gmapInfo.price) }} {{gmapInfo.currency}}</h6>
                    </div>
                  </div>
                </gmap-info-window>
              </GmapMap>
            </div>
          </div>

          <div class="col-12 col-properties" v-else>
            <swiper class="swiper" :options="propertiesOptions">
              <!-- Desarrollos -->
              <swiper-slide v-if="filtertype == 0" v-for="(p, phInx) in properties.projects" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/desarrollos/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>
                      <h5 class="price">Desde $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <!-- Propiedades vacacionales -->
              <swiper-slide v-if="filtertype == 1" v-for="(p, phInx) in properties.propertiesvacations" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/lotes/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                    <div class="box-extra-info">

                      <div class="col col-info">
                          <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                      </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <!-- Propiedad -->
              <swiper-slide v-if="filtertype == 2" v-for="(p, phInx) in properties.properties" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/lotes/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                    <div class="box-extra-info">

                      <div class="col col-info">
                          <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                      </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <!-- Terreno -->
              <swiper-slide v-if="filtertype == 3" v-for="(p, phInx) in properties.grounds" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/lotes/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                    <div class="box-extra-info">

                      <div class="col col-info">
                          <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                      </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>

              <!-- Comercial -->
              <swiper-slide v-if="filtertype == 4" v-for="(p, phInx) in properties.commercial" :key="'phInx-'+phInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/propiedades/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                    <div class="box-extra-info">

                      <div class="col col-info">
                          <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                      </div>

                      <div class="col col-info">
                        <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                      </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>

              <!-- Oficina -->
              <swiper-slide v-if="filtertype == 5" v-for="(p, prvInx) in properties.offices" :key="'prvInx-'+prvInx">
                <!-- <div class="col-md-6 col-lg-4 col-property-sample-1"> -->
                <div class="col-12 px-0 col-property-sample-1">
                  <router-link class="box-property" :to="'/propiedades/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ p.imageUrl +')' }">
                      <img src="public/images/pages/properties/property.png">
                    </div>

                    <div class="box-info">
                      <h6 class="name">{{p.name}}</h6>

                    <h5 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }} {{p.currency}}</h5>
                    <h5 class="type"><span>{{p.type_operation_name}}</span></h5>

                      <div class="box-extra-info">

                        <div class="col col-info">
                            <i class="icon total"></i> <span class="txt"><small>Terreno</small><br />{{p.meters_ground_sqft}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon built"></i> <span class="txt"><small>Construido</small><br />{{p.meters_building_sqft}} MTS²</span>
                        </div>

                        <div class="col col-info">
                          <i class="icon garden"></i> <span class="txt"><small>Jardín</small><br />{{p.meters_garden_sqft}} MTS²</span>
                        </div>

                      </div>
                    </div>

                    <div class="btn-more">EXPLORAR</div>
                  </router-link>
                </div>
              </swiper-slide>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 0 && properties.projects.length == 0">Por ahora no existen desarrollos</p>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 1 && properties.propertiesvacations.length == 0">Por ahora no existen propiedades disponibles bajo esta clasificacion</p>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 2 && properties.properties.length == 0">Por ahora no existen propiedades disponibles bajo esta clasificacion</p>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 3 && properties.grounds.length == 0">Por ahora no existen propiedades disponibles bajo esta clasificacion</p>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 4 && properties.commercial.length == 0">Por ahora no existen propiedades disponibles bajo esta clasificacion</p>
              <p style="text-align: center;font-size: 20px;width: 100%;" v-if="filtertype == 5 && properties.offices.length == 0">Por ahora no existen propiedades disponibles bajo esta clasificacion</p>

              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 0 && properties.projects.length > 0"></div>
              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 1 && properties.propertiesvacations.length > 0"></div>
              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 2 && properties.properties.length > 0"></div>
              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 3 && properties.grounds.length > 0"></div>
              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 4 && properties.commercial.length > 0"></div>
              <div class="swiper-pagination" slot="pagination" v-if="filtertype == 5 && properties.offices.length > 0"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="destinations-section">
      <div class="container oversized-container">
        <div class="mb-3 row">
          <div class="col-lg-6 col-title-t1">
            <h2 class="title">Destinos</h2>
            <router-link class="btn-all" to="/destinos">VER TODAS</router-link>
          </div>
        </div>

        <div class="row row-destinations-s1">
          <div class="col-md-6 col-lg-4 col-destination-s1" v-for="(d, dhInx) in destinations" :key="'dhInx-'+dhInx">
            <router-link class="placed-backg box-destination" v-bind:style="{ backgroundImage: 'url('+d.imageUrl+')' }" :to="'/destinos/'+d.id">
              <img class="fake-img" src="public/images/shared/destination.png" alt="">

              <div class="inside">
                <div class="box-explore"><span>VER MÁS</span></div>
                <div class="box-info">
                  <h5 class="name">{{ d.name }}</h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="extra-info-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 mb-4">
            <h2 class="title">Desarrollamos grandes ideas que venden</h2>
          </div>

          <div class="col-lg-10">
            <div class="box-extra-numbers-s1">
              <div class="col-lg-4 col-numbers">
                <h3 class="number">+50</h3>
                <h5 class="text">Desarrollos</h5>
              </div>

              <div class="col-lg-4 col-numbers">
                <h3 class="number">+700</h3>
                <h5 class="text">Clientes felices</h5>
              </div>

              <div class="col-lg-4 col-numbers">
                <h3 class="number">+1,000</h3>
                <h5 class="text">Propiedades en la playa</h5>
              </div>

              <div class="col-12 col-buttons">
                <router-link class="btn btn-prop" to="/propiedades">VER PROPIEDADES</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="mb-3 row">
          <div class="col-lg-6 col-title-t1">
            <h2 class="title">Blog</h2>
            <router-link class="btn-all" to="/blogs">VER TODAS</router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-article article-sample-1 lg" v-for="(article, ahInx) in blogs" :key="'ahInx-'+ahInx">
            <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blogs/'+article.id">
              <img class="empty" src="public/images/pages/blog/article-lg.png">

              <div class="inside">
                <div class="shadow"></div>
                <div class="box-txt">
                  <h6 class="category">BLOG</h6>
                  <h5 class="title">{{article.title}}</h5>

                  <!-- <h6 class="extra">POR VRN Realty</h6>
                  <h6 class="extra">{{article.created}}</h6> -->
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-3 instagram-section">
      <div class="elfsight-app-6c8fee68-6a4a-4579-910d-642f53c91bc2" data-elfsight-app-lazy></div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        pc: [],
        movil: []
      },

      filtertype: 0,
      showMap: false,

      categories: [
        { name: 'Desarrollos', hex: '#0bc0df' },
        { name: 'Propiedad Vacacional', hex: '#06bf64' },
        { name: 'Propiedad', hex: '#ff3232' },
        { name: 'Terreno', hex: '#ffde59' },
        { name: 'Comercial', hex: '#b34eff' },
        { name: 'Oficina', hex: '#a3a3a3' },

      ],

      destinations: [],
      blogs: [],
      properties:{
          maps:[],
          properties:[],
          sales:null,
          rents:null,
          vacations:null,
          projects:[],
          propertiesvacations:[],
          grounds:[],
          offices:[],
          commercial:[],

      },
      // == Markers values ==
      mapCenter: { id: null, position: { lat: 20.6906326, lng: -105.3213846, zoom: 12.7 } }, // Centro del mapa
      markers: [],
      windowOpen: false, // true, false para mostrar la ventana de los markers
      gmapInfo: { // Obj para la ventana de los markers
        position: {
          lat: 0, 
          lng: 0,
          zoom: 0,
        },
        content: ``
      },
      // == ==

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      propertiesOptions: {
        slidesPerView: 3,

        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      /* === */
    }
  },
  methods:{
    markAction(val) {
      this.gmapInfo = val;
      this.windowOpen = true;
    },

    getHome(){
      axios.get(tools.url("/api/home")).then((response)=>{
            this.banners = response.data.banners;
            this.destinations = response.data.destinations;
            this.properties = response.data.properties;
            this.blogs = response.data.blogs;
        }).catch((error)=>{
        });
    },
    showMapFun(){
      if(this.showMap == false){
          this.showMap = true;
          this.filtertype = null;
      }
      else{
          this.showMap = false;
          this.filtertype = 0;
      }

    }

  },
  mounted(){
    this.getHome();

  }
}
</script>
