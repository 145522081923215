<template lang="html">
  <div class="placed-backg" id="sales-page">

    <section class="container main-section">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-info">
          <div>
            <hr class="mb-5 hr" />
          </div>

          <h6 class="title-1">ENCONTREMOS A TU CLIENTE IDEAL</h6>

          <h2 class="mt-4 title-2">VENDE TU DESARROLLO CON NOSOTROS</h2>

          <div>
            <hr class="mb-4 mt-5 hr" />
          </div>

          <h4 class="title-3">NOSOTROS VENDEMOS TU PROYECTO</h4>
          <h5 class="mt-3 mt-sm-2 title-1">REGÍSTRATE EN EL SIGUIENTE FORMULARIO</h5>

          <p class="mt-5">
            <router-link class="t-250 btn-form" to="/vender/formulario">CONTACTAR</router-link>
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
