<template lang="html">
  <div id="info-page-s2" class="about-us-page">

    <div class="placed-backg img-fake l" v-bind:style="{ backgroundImage: 'url(public/images/pages/alliance/bg.jpg)' }"></div>

    <section class="container oversized-container text-justify main-section">
      <div class="row align-items-center">
        <div class="col-lg-6 order-2 order-lg-0 mt-2 mt-lg-0 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-lg-6 col-info">
          <h1 class="mb-2 title-s-1">ALIANZAS</h1>

          <p>
            Lorem ipsum dolor sit amet, aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <br />
          <p>
            Lorem elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <br />
          <h5 class="mb-2 f-w-700">Subtítulo de ejemplo.</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
