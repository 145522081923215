<template lang="html">
  <div id="info-page-s1">
    <section class="banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/faqs/banner.jpg)' }"></section>
    <section class="container text-justify main-section">
      <h1 class="mb-2 title-s-1">Preguntas frecuentes</h1>
      <div class="py-2" v-html="content"></div>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/faqs')).then((response)=>{
        this.content = response.data.body;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
      this.getContent();
  }
}
</script>
