<template lang="html">
  <div id="blog-page">

    <div class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-12 col-articles">
          <div class="row">
            <div class="col-12 mb-3">
              <h3 class="title-s-1 sm">BLOG</h3>
            </div>

            <div class="col-12 col-md-6 col-lg-4 col-article article-sample-1" v-for="(article, ahInx) in blogs" :key="'ahInx-'+ahInx">
              <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/blogs/'+article.id">
                <img class="empty" src="public/images/pages/blog/article-lg.png">

                <div class="inside">
                  <div class="shadow"></div>
                  <div class="box-txt">
                    <h6 class="category">BLOG</h6>
                    <h5 class="title">{{article.title}}</h5>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      blogs: [],
    }
  },
  methods:{
      getBlogs(){
        axios.get(tools.url("/api/blogs")).then((response)=>{
            this.blogs = response.data;
          }).catch((error)=>{
              this.$parent.handleErrors(error);
          });
      },
  },
  mounted(){
     this.getBlogs();
  }
}
</script>
