<template lang="html">
  <div id="destinations-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12">
          <h1 class="title-s-1 mb-2">Destinos</h1>
        </div>

        <div class="col-12 col-results">
          <div class="row row-destinations-s1">
            <div class="col-md-6 col-lg-4 col-destination-s1" v-for="(d, dhInx) in rows" :key="'dhInx-'+dhInx">
              <router-link class="placed-backg box-destination" v-bind:style="{ backgroundImage: 'url('+d.imageUrl+')' }" :to="'/destinos/'+d.id">
                <img class="fake-img" src="public/images/shared/destination.png" alt="">

                <div class="inside">
                  <div class="box-explore"><span>VER MÁS</span></div>
                  <div class="box-info">
                    <h5 class="name">{{ d.name }}</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: [],
    }
  },
  methods:{
    getData(){
      axios.get(tools.url("/api/destinations")).then((response)=>{
        this.rows = response.data;
      }).catch((error)=>{
      });
    },
  },
  mounted(){
      this.getData();
  }
}
</script>
